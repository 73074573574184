import * as React from "react"
import ReactDOM from "react-dom/client";
import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'

const $ = require("jquery")
export function wrapPageElement({ element, props }) {
    const Layout = element.type.Layout ?? React.Fragment
    return <Layout {...props}>{element}</Layout>
  }

  export const replaceHydrateFunction = () =>{
    return (element, container) => {
        const root = ReactDOM.createRoot(container)
        root.render(element)
      }
  }

export const onInitialClientRender = () => {
    
  $(document).ready(function () {
    console.log("Success")
  
    // Custom 
    var stickyToggle = function (sticky, stickyWrapper, scrollElement) {
        var stickyHeight = sticky.outerHeight();
        var stickyTop = stickyWrapper.offset().top;
        if (scrollElement.scrollTop() >= stickyTop) {
            stickyWrapper.height(stickyHeight);
            sticky.addClass("is-sticky");
        }
        else {
            sticky.removeClass("is-sticky");
            stickyWrapper.height('auto');
        }
    };

    // Find all data-toggle="sticky-onscroll" elements
    $('[data-toggle="sticky-onscroll"]').each(function () {
        var sticky = $(this);
        var stickyWrapper = $('<div>').addClass('sticky-wrapper'); // insert hidden element to maintain actual top offset on page
        sticky.before(stickyWrapper);
        sticky.addClass('sticky');

        // Scroll & resize events
        $(window).on('scroll.sticky-onscroll resize.sticky-onscroll', function () {
            stickyToggle(sticky, stickyWrapper, $(this));
        });

        // On page load
        stickyToggle(sticky, stickyWrapper, $(window));
    });

  });

  $(window).resize(function () {
    if ($(window).width() >= 980) {

        // when you hover a toggle show its dropdown menu
        $(".navbar .dropdown-toggle").hover(function () {
            $(this).parent().toggleClass("show");
            $(this).parent().find(".dropdown-menu").toggleClass("show");
        });

        // hide the menu when the mouse leaves the dropdown
        $(".navbar .dropdown-menu").mouseleave(function () {
            $(this).removeClass("show");
        });

        // do something here
    }
});
}